import React from 'react';
import { graphql } from 'gatsby';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import Layout from '../components/Layout';
import Image from '../components/Image';
import useSiteMetadata from '../components/SiteMetadata';
import MetaComponent from '../components/MetaComponent';

export const ContactPageTemplate = ({
  pageTitle,
  title,
  image,
  address,
  contact,
  socialTitle,
  helmet,
  socials
}) => {
  return (
    <div className='contact-page-container container content'>
      {helmet || ''}
      <h1 className='title contact-page-title'>{pageTitle}</h1>
      <div className='columns'>
        <div className='column is-1'></div>
        <div className='column is-5'>
          <Image
            imageInfo={{ image }}
            style={{ width: '90%' }}
            classes='contact-image'
          />
        </div>

        <div className='column is-5'>
          <hr className='hr-black' />
          <div className='contact-page-text'>{title}</div>
          <div className='columns contact-information'>
            <div className='column is-6'>
              <h3 className='text-header'>{address.addressTitle}</h3>
              <div className='address-text'>{address.address}</div>
              <h3 className='text-header'>{socialTitle}</h3>
              <div className='social'>
                <a
                  title='facebook'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={socials[0].url}>
                  <FaFacebook style={{ color: 'black', fontSize: '30px' }} />
                </a>
                <a
                  title='youtube'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={socials[1].url}>
                  <FaYoutube style={{ color: 'black', fontSize: '30px' }} />
                </a>
                <a
                  title='instagram'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={socials[2].url}>
                  <FaInstagram style={{ color: 'black', fontSize: '30px' }} />
                </a>
              </div>
            </div>
            <div className='column is-6'>
              <h3 className='text-header'>{pageTitle}</h3>
              <div>
                <a href={`tel:${contact.phone}`}>{contact.phone}</a>
              </div>
              <div>
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </div>
            </div>
          </div>
        </div>
        <div className='column is-1'></div>
      </div>
    </div>
  );
};

const ContactPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <ContactPageTemplate
        location={location}
        title={frontmatter.contactTitle}
        image={frontmatter.contactImage}
        pageTitle={frontmatter.pageTitle}
        address={frontmatter.contactAddress}
        contact={frontmatter.contactItems}
        socialTitle={frontmatter.socialTitle}
        socials={data.socials.frontmatter.socials}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.contactImage}`}
          />
        }
      />
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        pageTitle
        contactTitle
        contactImage
        contactAddress {
          addressTitle
          address
        }
        contactItems {
          phone
          email
        }
        socialTitle
        socialTitle
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
    socials: markdownRemark(frontmatter: { templateKey: { eq: "footer" } }) {
      frontmatter {
        socials {
          company
          url
        }
      }
    }
  }
`;
